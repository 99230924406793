import React from 'react';
import './ColoringAnalysis.css';


const DegToRad = Math.PI / 180;
const Colors = {
    "#ffffff": {
        name: "fehér",
        red: 0,
        yellow: 0,
        blue: 0,
    }, // white
    "#ffc0cb": {
        name: "rózsaszín",
        red: 4,
        yellow: 0,
        blue: 0,
    }, // pink
    "#ff0000": {
        name: "vörös",
        red: 4,
        yellow: 0,
        blue: 0,
    }, // red
    "#ff7f50": {
        name: "korall",
        red: 3,
        yellow: 1,
        blue: 0,
    }, // coral
    "#ffa500": {
        name: "narancs",
        red: 2,
        yellow: 2,
        blue: 0,
    }, // orange
    "#fdd017": {
        name: "arany",
        red: 1,
        yellow: 3,
        blue: 0,
    }, // gold
    "#ffff00": {
        name: "sárga",
        red: 0,
        yellow: 4,
        blue: 0,
    }, // yellow
    "#98bf64": {
        name: "olajzöld",
        red: 0,
        yellow: 3,
        blue: 1,
    }, // olive
    "#028a0f": {
        name: "zöld",
        red: 0,
        yellow: 2,
        blue: 2,
    }, // green
    "#00ced1": {
        name: "türkiz",
        red: 0,
        yellow: 1,
        blue: 3,
    }, // turquise
    "#1f45fc": {
        name: "kék",
        red: 0,
        yellow: 0,
        blue: 4,
    }, // blue
    "#1a1172": {
        name: "indigó",
        red: 1,
        yellow: 0,
        blue: 3,
    }, // indigo blue
    "#9400d3": {
        name: "lila",
        red: 2,
        yellow: 0,
        blue: 2,
    }, // purple
    "#ff00ff": {
        name: "magenta",
        red: 3,
        yellow: 0,
        blue: 1,
    }, // magenta
};

class Sector extends React.Component {
    getSectorPath(x, y, outerDiameter, a1, a2) {
        const cr = (outerDiameter / 2) - 5;
        const cx1 = (Math.cos(DegToRad * a2) * cr) + Number(x);
        const cy1 = (-Math.sin(DegToRad * a2) * cr) + Number(y);
        const cx2 = (Math.cos(DegToRad * a1) * cr) + Number(x);
        const cy2 = (-Math.sin(DegToRad * a1) * cr) + Number(y);

        return `M${x} ${y} ${cx1} ${cy1} A${cr} ${cr} 0 0 1 ${cx2} ${cy2}Z`;
    }

    getSectorText(x, y, a1, text) {
        const radius = 70;

        var angle, align, rotate;
        if ((a1 >= 90) && (a1 <= 240)) {
            angle = a1 + 19;
            rotate = a1 + 192;
            align = "end";
        } else {
            angle = a1 + 12;
            rotate = angle;
            align = "start";
        }
        const tx = Number(x) + radius * Math.cos(DegToRad * angle);
        const ty = Number(y) + radius * -Math.sin(DegToRad * angle);

        return (
            <text x={tx} y={ty} textAnchor={align} transform={`rotate(${-rotate} ${tx} ${ty})`} strokeWidth="1">{text}</text>
        );
    }

    render() {
        const path = this.getSectorPath(this.props.x, this.props.y, this.props.d, this.props.a1, this.props.a2);
        const id = `sector-${this.props.ident}`;
        const text = this.getSectorText(this.props.x, this.props.y, this.props.a1, Colors[this.props.color].name);

        return (
            <g>
                <path id={id} d={path} fill={this.props.color} />
                {text}
            </g>
        );
    }
}

class Circle extends React.Component {
    renderSector(i, color) {
        return (
            <Sector
                key={i.toString()}
                ident={i}
                x="0"
                y="0"
                d={this.props.diameter}
                a1={i * 30}
                a2={i * 30 + 30}
                color={color}
            />
        );
    }

    render() {
        var sectors = [];
        for (var i = 0; i < 12; i++) {
            // Fix rotation.
            const v = (12 - i + 5) % 12;
            sectors.push(this.renderSector(v, this.props.colors[i]));
        }

        return (
            <g id="circle" transform={`translate(${this.props.centerX},${this.props.centerY})`} stroke="#000" strokeWidth="2">
                {sectors}
            </g>
        );
    }
}


class Analysis extends React.Component {
    renderColorBreakdownText(x, y, title, obj) {
        var rc, yc, bc = "";
        if ((obj.red >= obj.yellow) && (obj.red >= obj.blue)) {
            rc = "bd-max";
        }
        if ((obj.yellow >= obj.red) && (obj.yellow >= obj.blue)) {
            yc = "bd-max";
        }
        if ((obj.blue >= obj.red) && (obj.blue >= obj.yellow)) {
            bc = "bd-max";
        }

        return (
            <g>
                <text key={title} x={x} y={y} className="bd-title">{title}</text>
                <text x={x + 20} y={y + 20} className={rc}>vörös: {obj.red}</text>
                <text x={x + 20} y={y + 40} className={yc}>sárga: {obj.yellow}</text>
                <text x={x + 20} y={y + 60} className={bc}>kék: {obj.blue}</text>
            </g>
        );
    }

    renderCircleOverlay(cx, cy, circleSize) {
        const halfSize = circleSize / 2;
        const axis = halfSize + 150;
        const sep = halfSize + 50;
        const p1x = sep * Math.cos(60 * DegToRad);
        const p1y = sep * -Math.sin(60 * DegToRad);
        const p2x = sep * Math.cos(120 * DegToRad);
        const p2y = sep * -Math.sin(120 * DegToRad);
        const p3x = sep * Math.cos(300 * DegToRad);
        const p3y = sep * -Math.sin(300 * DegToRad);
        const p4x = sep * Math.cos(240 * DegToRad);
        const p4y = sep * -Math.sin(240 * DegToRad);

        return (
            <g transform={`translate(${cx},${cy})`} stroke="#000" strokeWidth="2">
                <line x1="0" y1="0" x2={-axis} y2="0" />
                <line x1="0" y1="0" x2={axis} y2="0" />
                <text x="0" y={-halfSize - 20} textAnchor="middle" strokeWidth="1">Tudatos jelen</text>
                <text x="0" y={halfSize + 30} textAnchor="middle" strokeWidth="1">Tudatalatti jelen</text>
                <line x1="0" y1="0" x2={p1x} y2={p1y} />
                <line x1="0" y1="0" x2={p2x} y2={p2y} />
                <line x1="0" y1="0" x2={p3x} y2={p3y} />
                <line x1="0" y1="0" x2={p4x} y2={p4y} />
                <text textAnchor="start" strokeWidth="1">
                    <tspan x={halfSize + 10} y={-halfSize / 2 - 10}>Életerő, lendület, siker,</tspan>
                    <tspan x={halfSize + 10} y={-halfSize / 2 + 10}>alkalmazkodás, találékonyság</tspan>
                </text>
                <text textAnchor="start" strokeWidth="1">
                    <tspan x={halfSize + 10} y={halfSize / 2}>Progresszió,</tspan>
                    <tspan x={halfSize + 10} y={halfSize / 2 + 20}>irányultság</tspan>
                </text>
                <text textAnchor="end" strokeWidth="1">
                    <tspan x={-halfSize - 10} y={-halfSize / 2 - 10}>Gátlások, akadályok, titkok</tspan>
                    <tspan x={-halfSize - 10} y={-halfSize / 2 + 10}>önkép lebontás</tspan>
                </text>
                <text textAnchor="end" strokeWidth="1">
                    <tspan x={-halfSize - 10} y={halfSize / 2}>Karmikus és regresszív</tspan>
                    <tspan x={-halfSize - 10} y={halfSize / 2 + 20}>információk</tspan>
                </text>
            </g>
        );
    }

    render() {
        var global = {
            red: 0,
            yellow: 0,
            blue: 0,
        };
        var conscious = {
            red: 0,
            yellow: 0,
            blue: 0,
        };
        var subconscious = {
            red: 0,
            yellow: 0,
            blue: 0,
        };

        for (var i = 0; i < this.props.colors.length; i++) {
            const c = Colors[this.props.colors[i]];
            global.red += c.red;
            global.yellow += c.yellow;
            global.blue += c.blue;

            if (i < 6) {
                conscious.red += c.red;
                conscious.yellow += c.yellow;
                conscious.blue += c.blue;
            } else {
                subconscious.red += c.red;
                subconscious.yellow += c.yellow;
                subconscious.blue += c.blue;
            }
        }

        var colorBreakdown = [];
        colorBreakdown.push(this.renderColorBreakdownText(100, 690, "Globális", global));
        colorBreakdown.push(this.renderColorBreakdownText(360, 690, "Tudatos", conscious));
        colorBreakdown.push(this.renderColorBreakdownText(600, 690, "Tudatalatti", subconscious));

        const size = 800;
        const circleSize = 500;
        const overlay = this.renderCircleOverlay(size / 2, size / 2.5, circleSize * 2 / 3);

        return (
            <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                <rect width={size} height={size} fill="none" stroke="#000" strokeWidth="1" />
                <Circle centerX={size / 2} centerY={size / 2.5} diameter={circleSize * 2 / 3} colors={this.props.colors} />
                {overlay}
                {colorBreakdown}
            </svg>
        );
    }
}

/*

Example code:
I2ZmYTUwMCwjOTQwMGQzLCNmZjdmNTAsI2ZmYzBjYiwjZmZmZmZmLCNmZjAwMDAsI2ZmZmYwMCwjMGExMTcyLCMxZjQ1ZmMsIzAyOGEwZiwjOThiZjY0LCNmZGQwMTc=

*/

class CircleAnalysis extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            colors: []
        };
    }

    analyse() {
        // Remove current state.
        if (this.state.colors.length > 0) {
            this.setState({ colors: [] });
        }

        // Checking base64 encoding.
        try {
            var colors = atob(document.getElementById("code").value).split(",");
        } catch (e) {
            alert("Hibás kód!");
            return;
        }

        if ((colors.length === 1) && (colors[0] === "")) {
            alert("Üres kód!");
            return;
        }

        if (colors.length !== 12) {
            alert("Túl kevés szín!");
            return;
        }

        // Checking if array elements are the 14 selected colors.
        // NOTE: Do not use foreach because of the return.
        for (var i = 0; i < colors.length; i++) {
            if (!Colors.hasOwnProperty(colors[i])) {
                alert("Hibás színkód: " + colors[i]);
                return;
            }
        }

        this.setState({ colors: colors });
    }

    render() {
        let analysis;
        if (this.state.colors.length > 0) {
            analysis = <Analysis colors={this.state.colors} />;
        }

        return (
            <div className="ColoringAnalysis">
                <h1>Színterápia elemzés</h1>
                <div className="row">
                    <textarea id="code" rows="3" cols="50" />
                </div>
                <div className="row">
                    <button onClick={() => this.analyse()}>Elemzés!</button>
                </div>
                <div className="row" id="analysis">
                    {analysis}
                </div>
            </div>
        );
    }
}

export default CircleAnalysis;
