import React from 'react';
import './Landing.css';

function Landing() {
  return (
    <div className="Landing">
      <h1 className="Title">Applications</h1>
      <nav className="Nav">
        <ul>
          <li><a href="/coloring">Coloring</a></li>
        </ul>
      </nav>
    </div>
  );
}

export default Landing;
