import React from 'react';
import './Coloring.css';
import { Circle, ColorPicker } from '../common/Colors';


class CircleColoring extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedColor: "#ffffff",
            firstSector: undefined,
        };
    }

    selectColor(color) {
        this.setState({ selectedColor: color });
    }

    getSelectedColor(i) {
        if (typeof this.state.firstSector === 'undefined') {
            var state = Object.assign({}, this.state);
            state.firstSector = i;
            this.setState(state);
        }

        return this.state.selectedColor;
    }

    export() {
        var order = [];
        for (var i = 0; i < 12; i++) {
            const id = ((this.state.firstSector || 0) - i + 12) % 12;
            order.push(document.getElementById("sector-" + id.toString()).getAttribute("fill"));
        }
        const result = btoa(order.join(","));
        document.getElementById("result").innerHTML = result;
    }

    copy() {
        var result = document.getElementById("result");
        result.select();
        result.setSelectionRange(0, 99999);
        document.execCommand("copy");
        alert("Result copied to clipboard");
    }

    render() {
        const size = 500;

        return (
            <div className="Coloring">
                <h1>Coloring</h1>
                <svg width="100%" height={size} viewBox={`0 0 ${size} ${size}`}>
                    <rect width={size} height={size} fill="none" stroke="#000" strokeWidth="1" />
                    <Circle centerX={size / 2} centerY={size / 2.5} diameter={size * 2 / 3} apply={(i) => this.getSelectedColor(i)} />
                    <ColorPicker width={size} y={size - 100} select={(color) => this.selectColor(color)} />
                </svg>
                <div className="row">
                    <button onClick={() => this.export()}>Generate code</button>
                </div>
                <div className="row">
                    <textarea id="result" rows="3" cols="50" readOnly />
                </div>
                <div className="row">
                    <button onClick={() => this.copy()}>Copy to clipboard</button>
                </div>
            </div>
        );
    }
}

export default CircleColoring;
