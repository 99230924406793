import React from 'react';
import './Numerology.css';

// TODO: is Y a vowel sometimes?
const Vowels = ["A", "E", "I", "O", "U"];
const Letters = {
    "A": 1, "B": 2, "C": 3, "D": 4, "E": 5, "F": 6, "G": 7, "H": 8, "I": 9,
    "J": 1, "K": 2, "L": 3, "M": 4, "N": 5, "O": 6, "P": 7, "Q": 8, "R": 9,
    "S": 1, "T": 2, "U": 3, "V": 4, "W": 5, "X": 6, "Y": 7, "Z": 8,
};
const cubeSize = 99;

class Numerology extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            names: [],
            dates: [],
        };
    }

    reduce(n) {
        if (n < 10) {
            return parseInt(n, 10);
        } else {
            let sum = 0;
            for (const c of n.toString()) {
                sum += parseInt(c, 10);
            }
            return this.reduce(sum);
        }
    }

    count(s) {
        var h = {};
        for (const c of s.toString()) {
            let n = parseInt(c, 10);
            if (!isNaN(n) && n > 0) {
                if (!h.hasOwnProperty(n)) {
                    h[n] = 0;
                }
                h[n]++;
            }
        }
        return h;
    }

    merge(...objs) {
        var res = {};
        for (const obj of objs) {
            for (const [num, val] of Object.entries(obj)) {
                if (!res.hasOwnProperty(num)) {
                    res[num] = val;
                } else {
                    res[num] += val;
                }
            }
        }
        return res;
    }

    renderCube(numbers) {
        let numTexts = [];

        for (const [n, val] of Object.entries(numbers)) {
            if (val > 0) {
                const x = (((n - 1) % 3) * (cubeSize / 3)) + 15;
                const y = (Math.floor((n - 1) / 3) * (cubeSize / 3)) + 22;
                numTexts.push(function () {
                    return (
                        <text x={x} y={y} textAnchor="middle" stroke="#f0f" strokeWidth="0.5">{val}</text>
                    );
                }());
            }
        }

        return (
            <div className="Cube">
                <svg width={cubeSize} height={cubeSize} viewBox={`0 0 ${cubeSize} ${cubeSize}`}>
                    <rect width={cubeSize} height={cubeSize} fill="none" stroke="#000" strokeWidth="2" />
                    <g stroke="#000" strokeWidth="1">
                        <line x1={cubeSize / 3} y1={0} x2={cubeSize / 3} y2={cubeSize} />
                        <line x1={cubeSize / 3 * 2} y1={0} x2={cubeSize / 3 * 2} y2={cubeSize} />
                        <line y1={cubeSize / 3} x1={0} y2={cubeSize / 3} x2={cubeSize} />
                        <line y1={cubeSize / 3 * 2} x1={0} y2={cubeSize / 3 * 2} x2={cubeSize} />
                    </g>
                    {numTexts}
                </svg>
            </div>
        );
    }

    analyseDate() {
        const raw = document.getElementById("date").value;
        if (raw.length === 0) {
            return;
        }

        var item = {
            year: 0,
            month: 0,
            day: 0,
        };
        [item.year, item.month, item.day] = raw.split("-");

        item.yearVal = this.reduce(item.year);
        item.monthVal = this.reduce(item.month);
        item.dayVal = this.reduce(item.day);
        item.total = this.reduce(item.yearVal + item.monthVal + item.dayVal);

        var currentNames = this.state.names;
        var currentDates = this.state.dates;
        currentDates.push(item);

        this.setState({
            names: currentNames,
            dates: currentDates,
        });
    }

    renderDateItem(i, item) {
        const c = this.renderCube(
            this.merge(
                this.count(item.year),
                this.count(item.month),
                this.count(item.day),
                this.count(item.total),
            )
        );

        return (
            <div className="DateItem" key={i.toString()}>
                <div>
                    év: {item.year} = {item.yearVal}<br />
                    hó: {item.month.padStart(4, "\u00A0")} = {item.monthVal}<br />
                    nap: {item.day.padStart(4, "\u00A0")} = {item.dayVal}<br />
                    sorsmisszió: {item.total}
                </div>
                {c}
                <button className="DelButton" onClick={() => this.removeDateItem(i)}>Törlés</button>
            </div>
        );
    }

    removeDateItem(i) {
        var currentNames = this.state.names;
        var currentDates = this.state.dates;

        currentDates.splice(i, 1);

        this.setState({
            names: currentNames,
            dates: currentDates,
        });
    }

    analyseName() {
        const raw = document.getElementById("name").value;
        if (raw.length === 0) {
            return;
        }
        const normalized = raw.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase();
        var item = {
            raw: raw,
            vowels: "",
            vowelsVal: 0,
            consonants: "",
            consonantsVal: 0,
        };

        for (const c of normalized) {
            if (c === " ") {
                item.vowels += c;
                item.consonants += c;
                continue;
            }
            const value = Letters[c];
            if (Vowels.indexOf(c) >= 0) {
                // It's a vowel.
                item.vowelsVal += value;
                item.vowels += value.toString();
                item.consonants += "_";
            } else {
                // It's a consonant.
                item.consonantsVal += value;
                item.consonants += value.toString();
                item.vowels += "_";
            }
        }

        var currentNames = this.state.names;
        var currentDates = this.state.dates;
        currentNames.push(item);

        this.setState({
            names: currentNames,
            dates: currentDates,
        });
    }

    renderNameItem(i, item) {
        const total = item.vowelsVal + item.consonantsVal;
        const c = this.renderCube(
            this.merge(
                this.count(item.vowels),
                this.count(item.consonants),
            )
        );

        return (
            <div className="NameItem" key={i.toString()}>
                <div>
                    <div className="NameComponents">
                        {item.vowels} = <span className="NameComponentsSum">{item.vowelsVal.toString().padStart(3, "\u00A0")}</span> = {this.reduce(item.vowelsVal)}<br />
                        {item.raw} = <span className="NameTotal">{total.toString().padStart(3, "\u00A0")}</span> = {this.reduce(total)}<br />
                        {item.consonants} = <span className="NameComponentsSum">{item.consonantsVal.toString().padStart(3, "\u00A0")}</span> = {this.reduce(item.consonantsVal)}<br />
                    </div>
                </div>
                {c}
                <button className="DelButton" onClick={() => this.removeNameItem(i)}>Törlés</button>
            </div>
        );
    }

    removeNameItem(i) {
        var currentNames = this.state.names;
        var currentDates = this.state.dates;

        currentNames.splice(i, 1);

        this.setState({
            names: currentNames,
            dates: currentDates,
        });
    }

    render() {
        var names = [];
        for (let i = 0; i < this.state.names.length; i++) {
            names.push(this.renderNameItem(i, this.state.names[i]));
        }

        var dates = [];
        for (let i = 0; i < this.state.dates.length; i++) {
            dates.push(this.renderDateItem(i, this.state.dates[i]));
        }

        return (
            <div className="Numerology">
                <h1>Numerológia</h1>
                <div className="NameAnalysis">
                    <h2>Név elemzés</h2>
                    <div className="row">
                        <label for="name">Név:</label>
                        <input type="text" id="name" maxLength="50" size="30" />
                        <button onClick={() => this.analyseName()}>Elemzés!</button>
                    </div>
                    <div className="row">
                        {names}
                    </div>
                </div>
                <div className="DateAnalysis">
                    <h2>Dátum elemzés</h2>
                    <div className="row">
                        <label for="date">Dátum:</label>
                        <input type="date" id="date" min="1000-01-01" max="2099-12-31" />
                        <button onClick={() => this.analyseDate()}>Elemzés!</button>
                    </div>
                    <div className="row">
                        {dates}
                    </div>
                </div>
            </div>
        );
    }
}

export default Numerology;
